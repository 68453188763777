.lim-footer {
    height: 22px;
    width: 100vw;
    background-color: rgb(0, 0, 0);
    border-top: 1px gray solid;
    position: fixed;
    left: 0;
    bottom: 0;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lim-footer-left  {
    text-align: left;
    margin-left: 10px
}

.footer-version {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .lim-footer {
        display: none;
    }
}
